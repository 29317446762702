import './Location.css';
import {MapContainer, TileLayer, Marker, useMap} from 'react-leaflet';
import L from 'leaflet';
import locationIcon from '../../assets/location.png';
import 'leaflet/dist/leaflet.css';
import Menu from "../../components/menu/Menu";
import {useEffect, useState} from "react";

const Location = () => {

    const [position, setPosition] = useState([0, 0]);

    const ruLocation = [46.1418341, -1.1518132];

    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setPosition([position.coords.latitude, position.coords.longitude]);
                },
            );
        }
    }

    useEffect(() => {
        getLocation();
    }, []);

    const customIcon = L.icon({
        iconUrl: locationIcon,
        iconSize: [40, 40],
        iconAnchor: [20, 40],
        popupAnchor: [0, -40]
    });

    function showPosition(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        setPosition([latitude, longitude]);
    }

    function getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2) {
        var R = 6371; // Rayon du globe terrestre en km
        var dLat = deg2rad(lat2-lat1);
        var dLon = deg2rad(lon2-lon1);
        var a =     Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)    ;
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c; // Distance en km
        return d;
    }

    function deg2rad(deg) {
        return deg * (Math.PI/180)
    }

    function hasLocation() {
        return position[0] !== 0 && position[1] !== 0;
    }

    function getLocationStr() {
        if(!hasLocation()) {
            return "Je n'ai pas accès à votre position.";
        }
        return "Votre position : Lat:" + (Math.round(position[0] * 100) / 100) + ", Lon:" + (Math.round(position[1] * 100) / 100);
    }

    getLocation();
    const distance = Math.round(getDistanceFromLatLonInKm(position[0], position[1], ruLocation[0], ruLocation[1]) * 100) / 100;

    return (
        <>
            <Menu />
            <main className="page">
                <h1>Location</h1>
                <p className="location__position">{getLocationStr()}</p>
                {hasLocation() && (
                    <>
                        <p>Distance avec La Rochelle : {distance}km</p>
                        <p>(à vol d'oiseau bien sûr gné hé)</p>
                        <div className="location__map">
                            <MapContainer center={position} zoom={13} scrollWheelZoom={false}
                                          style={{height: '400px', width: '100%'}}>
                                <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={position} icon={customIcon}/>
                            </MapContainer>
                        </div>
                    </>
                )}
            </main>
        </>
    );
}

export default Location;