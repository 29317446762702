import './Phone.css';
import Menu from "../../components/menu/Menu";
import {useState} from "react";

const Phone = () => {

    const [phoneNumbers, setPhoneNumbers] = useState("");

    const pushNumber = (number) => {
        setPhoneNumbers(phoneNumbers + number);
    }

    const sliceNumber = () => {
        setPhoneNumbers(phoneNumbers.slice(0, -1));
    }

    const contactsAvailable = () => {
        return 'contacts' in navigator && 'ContactsManager' in window;
    }

    const selectContact = async () => {
        if (contactsAvailable()) {
            try {
                const props = ['name', 'tel'];
                const opts = { multiple: false };
                const contacts = await navigator.contacts.select(props, opts);
                if (contacts.length > 0 && contacts[0].tel.length > 0) {
                    setPhoneNumbers(contacts[0].tel[0]);
                }
            } catch (error) {
                console.error('Error selecting contact: ', error);
            }
        }
    }

    return (
        <>
            <Menu />
            <main className="page">
                <h1>Phone</h1>
                <div>
                    <input className="phone__input" type="text" placeholder="Saisissez numéro" disabled value={phoneNumbers}/>
                    <div className="phone__keyboard">
                        <div className="phone__keyboard__row">
                            <button onClick={() => pushNumber(1)}>1</button>
                            <button onClick={() => pushNumber(2)}>2</button>
                            <button onClick={() => pushNumber(3)}>3</button>
                        </div>
                        <div className="phone__keyboard__row">
                            <button onClick={() => pushNumber(4)}>4</button>
                            <button onClick={() => pushNumber(5)}>5</button>
                            <button onClick={() => pushNumber(6)}>6</button>
                        </div>
                        <div className="phone__keyboard__row">
                            <button onClick={() => pushNumber(7)}>7</button>
                            <button onClick={() => pushNumber(8)}>8</button>
                            <button onClick={() => pushNumber(9)}>9</button>
                        </div>
                        <div className="phone__keyboard__row">
                            <button onClick={() => pushNumber("*")}>*</button>
                            <button onClick={() => pushNumber(0)}>0</button>
                            <button onClick={() => pushNumber("#")}>#</button>
                        </div>
                        <div className="phone__keyboard__row">
                            <button className="phone__largebutton" onClick={() => sliceNumber()}>Retour</button>
                            <button className="phone__largebutton" onClick={() => setPhoneNumbers("")}>Effacer</button>
                        </div>
                        <div className="phone__keyboard__row phone__keyboard__call">
                            <button className="phone__largebutton"
                                    onClick={() => window.open('tel:' + phoneNumbers)}>Appeler
                            </button>
                        </div>
                        {contactsAvailable() ? (
                            <div className="phone__keyboard__row phone__keyboard__call phone__endpage">
                                <button className="phone__largebutton" onClick={() => selectContact()}>Contacts</button>
                            </div>
                        ) : (
                            <div className="phone__keyboard__row phone__keyboard__call phone__endpage">
                                <span>Contacts non disponible</span>
                            </div>
                        )}
                    </div>
                </div>
            </main>
        </>
    );
}

export default Phone;