import './Menu.css';
import {Link} from "react-router-dom";
import UilApps from "@iconscout/react-unicons/icons/uil-apps";
import UilCamera from "@iconscout/react-unicons/icons/uil-camera";
import UilLocationPoint from "@iconscout/react-unicons/icons/uil-location-point";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import {useEffect, useState} from "react";
import {
    UilBatteryBolt,
    UilBatteryEmpty, UilComment,
    UilMobileAndroid,
    UilPhone,
    UilWifi,
    UilWifiSlash
} from "@iconscout/react-unicons";
import {useBattery} from "react-use";

const Menu = () => {

    const [connected, setConnected] = useState(navigator.onLine);

    const battery = useBattery();
    const { isSupported, level, charging } = battery;

    useEffect(() => {
        const navToggle = document.getElementById('nav-toggle');
        const navClose = document.getElementById('nav-close');
        const navMenu = document.getElementById('nav-menu');

        function showMenu() {
            navMenu.classList.add('show-menu');
        }

        function hideMenu() {
            navMenu.classList.remove('show-menu');
        }

        if (navToggle) {
            navToggle.addEventListener('click', showMenu);
        }

        if (navClose) {
            navClose.addEventListener('click', hideMenu);
        }

        const navLinks = document.querySelectorAll('.nav__link');
        function handleLinkClick() {
            hideMenu();
        }

        navLinks.forEach(link => {
            link.addEventListener('click', handleLinkClick);
        });

        return () => {
            if (navToggle) {
                navToggle.removeEventListener('click', showMenu);
            }
            if (navClose) {
                navClose.removeEventListener('click', hideMenu);
            }
            navLinks.forEach(link => {
                link.removeEventListener('click', handleLinkClick);
            });
        };
    }, []);

    useEffect(() => {
        function handleOnline() {
            setConnected(true);
        }

        function handleOffline() {
            setConnected(false);
        }

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return(
        <header className="header" id="header">
            <nav className="nav container">
                <div className="nav__maininfos">
                    <Link to={"/"} className="nav__logo">PWA APP</Link>
                    <div className="nav__battery">
                        { charging ? (
                            <UilBatteryBolt />
                        ) : (
                            <UilBatteryEmpty/>
                        )}
                        { isSupported ? (
                            <span>{Math.round(level * 100)}%</span>
                        ) : (
                            <span>(iOS)</span>
                        )}
                    </div>
                    <div>
                        {connected ? (
                            <UilWifi />
                        ) : (
                            <UilWifiSlash />
                        )}
                    </div>
                </div>

                <div className="nav__menu" id="nav-menu">
                    <ul className="nav__list grid">
                        <li className="nav__item">
                            <Link to={"/device"} className="nav__link">
                                <UilMobileAndroid className="nav__icon"/>
                                Device
                            </Link>
                        </li>
                        <li className="nav__item">
                            <Link to={"/camera"} className="nav__link">
                                <UilCamera className="nav__icon"/>
                                Camera
                            </Link>
                        </li>
                        <li className="nav__item">
                            <Link to={"/location"} className="nav__link">
                                <UilLocationPoint className="nav__icon"/>
                                Location
                            </Link>
                        </li>
                        <li className="nav__item">
                            <Link to={"/phone"} className="nav__link">
                                <UilPhone className="nav__icon"/>
                                Phone
                            </Link>
                        </li>
                        <li className="nav__item">
                            <Link to={"/chat"} className="nav__link">
                                <UilComment className="nav__icon"/>
                                Chat
                            </Link>
                        </li>
                    </ul>
                    <UilTimes className="nav__close" id="nav-close"/>
                </div>

                <div className="nav__btns">

                    <div className="nav__toggle" id="nav-toggle">
                        <UilApps/>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Menu;