import DeviceUtils from "./DeviceUtils";

class NotificationUtils {
    static requestNotificationPermission = async () => {
        const permission = await Notification.requestPermission();
        return permission;
    };

    static sendNotification = (permission, notificationTitle, notificationBody) => {
        if (permission === "granted") {
            DeviceUtils.vibrate(400)
            new Notification(notificationTitle, {
                body: notificationBody,
            });
        }
    };

    static notificationStr = (notificationPermission) => {
        if(DeviceUtils.isIOS()) return "Notifications non disponibles sur iOS"
        switch(notificationPermission) {
            case "granted":
                return "granted ✅";
            case "denied":
                return "denied ❌";
            case "default":
                return "default ⚠️";
            case null:
                return "Notifications non prise en charge sur l'appareil";
            default:
                return "unknown ⚠️";
        }
    }
}

export default NotificationUtils;