import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./pages/home/Home";
import Camera from "./pages/camera/Camera";
import Location from "./pages/location/Location";
import Device from "./pages/device/Device";
import Phone from "./pages/phone/Phone";
import Chat from "./pages/social/Chat";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path={"/device"} element={<Device />} />
        <Route path={"/camera"} element={<Camera />} />
        <Route path={"/location"} element={<Location />} />
        <Route path={"/phone"} element={<Phone />} />
        <Route path={"/chat"} element={<Chat />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
