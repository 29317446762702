import './Camera.css';
import Menu from "../../components/menu/Menu";
import {useEffect, useRef, useState} from "react";
import DeviceUtils from "../../utils/DeviceUtils";
import NotificationUtils from "../../utils/NotificationUtils";

const Camera = () => {

    const videoRef = useRef(null);
    const [storedPhotos, setStoredPhotos] = useState(new Map());
    const [notificationPermission, setNotificationPermission] = useState(null);
    const [connected, setConnected] = useState(navigator.onLine);
    const [offlinePictures, setOfflinePictures] = useState([]);

    useEffect( () => {

        try {
            navigator.mediaDevices.getUserMedia({ video: true })
            .then( (mediaStream)=> {
                if (videoRef.current) {
                    videoRef.current.srcObject = mediaStream;
                }

            })
            .catch( (error) => {
                console.error('Erreur : ', error);
            })

        } catch (error) {
            console.error('Erreur : ', error)
        }

        const storedPhotos = new Map();
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key.startsWith('capturedPhoto')) {
                storedPhotos.set(key, localStorage.getItem(key));
            }
        }
        setStoredPhotos(storedPhotos);

        if(!DeviceUtils.isIOS()){
            NotificationUtils.requestNotificationPermission().then((permission) => {
                setNotificationPermission(permission);
            });
        }

    }, []);

    useEffect(() => {
        function handleOnline() {
            setConnected(true);

            if(offlinePictures.length > 0) {
                if(!DeviceUtils.isIOS() && notificationPermission === "granted") {
                    NotificationUtils.sendNotification(notificationPermission, 'Photos synchronisées', offlinePictures.length + ' photos ont été synchronisées !')
                }
                setOfflinePictures([])
            }
        }

        function handleOffline() {
            setConnected(false);
        }

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    useEffect(() => {
        if(!DeviceUtils.isIOS()){
            NotificationUtils.requestNotificationPermission().then((permission) => {
                setNotificationPermission(permission);
            });
        }
    }, []);

    const capturePhoto = () => {
        if (videoRef.current) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            canvas.getContext('2d').drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
            const imageDataURL = canvas.toDataURL('image/png');
            const newPhotoKey = `capturedPhoto-${Date.now()}`;

            localStorage.setItem(newPhotoKey, imageDataURL);
            setStoredPhotos(prevStoredPhotos => new Map([...prevStoredPhotos, [newPhotoKey, imageDataURL]]));

            if(!connected){
                offlinePictures.push(newPhotoKey);
            }

            if (!DeviceUtils.isIOS() && document.querySelector('input[type="checkbox"]').checked && notificationPermission === "granted") {
                NotificationUtils.sendNotification(notificationPermission, 'Nouvelle photo', 'Une nouvelle photo a été capturée !')
            }
        }
    };

    const deletePhoto = (photoKey) => {
        localStorage.removeItem(photoKey);
        setStoredPhotos(prevStoredPhotos => {
            const newStore = new Map(prevStoredPhotos);
            newStore.delete(photoKey);
            return newStore;
        });
    };

    const storedPhotosArray = Array.from(storedPhotos);

    return (
        <>
            <Menu />
            <main className="page">
                <h1>Camera</h1>
                <video className="camera__video" ref={videoRef} autoPlay playsInline/>
                <button className="camera__button" onClick={capturePhoto}>Prendre une photo</button>
                <div className="camera__notifsection">
                    {DeviceUtils.isIOS() ? (
                        <p>Les notifications ne sont pas disponibles sur iOS</p>
                    ) : (
                        <>
                            <div>
                                <p>Notifications : {NotificationUtils.notificationStr(notificationPermission)}</p>
                            </div>
                            <div className="camera__togglenotif">
                                <span>Afficher une notification</span>
                                <input type="checkbox"/>
                            </div>
                        </>
                    )}
                </div>
                <div>
                    <h2>Photos enregistrées</h2>
                    {storedPhotosArray.length === 0 ? <p className="camera__nopicture">Aucune photo
                        enregistrée</p> : storedPhotosArray.map(([key, value]) => (
                        <div className="camera__pictures" key={key}>
                            <img className="camera__picture" src={value} alt={`Captured ${key}`}/>
                            <button className="camera__button" onClick={() => deletePhoto(key)}>Supprimer</button>
                        </div>
                    ))}
                </div>
            </main>
        </>
    )
}

export default Camera;