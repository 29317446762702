import './Chat.css';
import Menu from "../../components/menu/Menu";
import { useEffect, useState } from "react";
import { Client } from '@stomp/stompjs';
import qrchat from '../../assets/qrchat.png';

const Chat = () => {

    const [messages, setMessages] = useState([]);
    const [client, setClient] = useState(null);
    const [message, setMessage] = useState('');
    const [connected, setConnected] = useState(false);

    useEffect(() => {
        const stompClient = new Client({
            brokerURL: 'ws://51.68.86.145:15674/ws',
            connectHeaders: {
                login: 'ctrempe',
                passcode: 'azerty'
            },
            onConnect: () => {
                console.log('Connected to RabbitMQ');
                setConnected(true);
                stompClient.subscribe('/exchange/chat', (message) => {
                    setMessages((prevMessages) => [...prevMessages, message.body]);
                });
            },
            onStompError: (frame) => {
                console.error('Broker reported error: ' + frame.headers['message']);
                console.error('Additional details: ' + frame.body);
            },
            onWebSocketError: (event) => {
                console.error('WebSocket error: ', event);
            }
        });

        console.log("Activate")
        stompClient.activate();
        setClient(stompClient);

        return () => {
            console.log("Deactivate")
            //stompClient.deactivate();
        };
    }, []);

    const sendMessage = () => {
        if (client && message) {
            client.publish({ destination: '/exchange/chat', body: message });
            setMessage('');
        }
    };

    return (
        <>
            <Menu />
            <main className="page">
                <h1>Chat</h1>
                {connected ? (
                    <>
                        <div>
                            {connected ? 'Connected' : 'Disconnected'}
                        </div>
                        <div>
                            {messages.map((msg, index) => (
                                <div key={index}>{msg}</div>
                            ))}
                        </div>
                        <input
                            type="text"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <button onClick={sendMessage}>Send</button>
                    </>
                ) : (
                    <img src={qrchat} alt="QR Code" className="chat__qrcode" />
                )}
            </main>
        </>
    )
}

export default Chat;
