import Menu from "../../components/menu/Menu";
import './Home.css';
import qrcode from '../../assets/qrcode.png';

const Home = () => {
    return (
        <>
            <Menu />
            <main className="center">
                <h1>PWA APP</h1>
                <p className="home__author">Clément Trempé</p>
                <img src={qrcode} alt="QR Code" className="home__qrcode" />
            </main>
        </>
    )
}

export default Home;