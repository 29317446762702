import Menu from "../../components/menu/Menu";
import {useEffect, useState} from "react";
import DeviceUtils from "../../utils/DeviceUtils";
import NotificationUtils from "../../utils/NotificationUtils";
import {useBattery} from "react-use";

const Device = () => {

    const [notificationPermission, setNotificationPermission] = useState(null);

    const battery = useBattery();

    const { isSupported, level } = battery;

    useEffect(() => {
        if(!DeviceUtils.isIOS()){
            NotificationUtils.requestNotificationPermission().then((permission) => {
                setNotificationPermission(permission);
            });
        }
    }, []);

    function vibrate() {
        DeviceUtils.vibrate(500)
    }

    function sendNotification(){
        if(!DeviceUtils.isIOS()){
            NotificationUtils.sendNotification(notificationPermission, 'Test notification', 'Les notifications fonctionnent avec succès !');
        }
    }

    return (
        <>
            <Menu />
            <main className="center">
                <h1>Device</h1>
                <div>
                    <h2>Vibration</h2>
                    {DeviceUtils.isIOS() ? (
                        <p>La vibration n'est pas disponible sur iOS</p>
                    ) : (
                        <button onClick={() => vibrate()}>Faire vibrer</button>
                    )}
                </div>
                <div>
                    <h2>Notifications</h2>
                    <p>{NotificationUtils.notificationStr(notificationPermission)}</p>
                    {notificationPermission === "granted" && (
                        <button onClick={() => sendNotification()}>Envoyer une notification</button>
                    )}
                </div>
                <div>
                    <h2>Batterie</h2>
                    {isSupported ? (
                        <>
                            <p>Niveau de batterie : {Math.round(level * 100)}%</p>
                        </>
                    ) : (
                        <p>La batterie n'est pas supportée sur iOS</p>
                    )}
                </div>
            </main>
        </>
    );
}

export default Device;