class DeviceUtils {
    static isIOS() {
        const browserInfo = navigator.userAgent.toLowerCase();

        if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
            return true;
        }
        if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
            return true;
        }
        return false;
    }

    static vibrate(duration){
        if(navigator.vibrate){
            navigator.vibrate(duration);
        }
    }
}

export default DeviceUtils;